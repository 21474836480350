// Generated by Framer (0dc8c44)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["PqhtvaQzT"];

const serializationHash = "framer-rci6D"

const variantClassNames = {PqhtvaQzT: "framer-v-14ziw1k"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({color, height, id, title, width, ...props}) => { return {...props, Bg5qY330d: title ?? props.Bg5qY330d ?? "Weniger X", PH9HMfixY: color ?? props.PH9HMfixY ?? "rgb(255, 255, 255)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Bg5qY330d, PH9HMfixY, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PqhtvaQzT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-14ziw1k", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PqhtvaQzT"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-1ilptbr-container"} layoutDependency={layoutDependency} layoutId={"d86t2Bx05-container"}><Phosphor color={PH9HMfixY} height={"100%"} iconSearch={"House"} iconSelection={"Check"} id={"d86t2Bx05"} layoutId={"d86t2Bx05"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "12.8479px", "--framer-letter-spacing": "-0.128479px", "--framer-line-height": "16.0598px", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-PH9HMfixY-uqiCD9Ms9))"}}>Weniger X</motion.p></React.Fragment>} className={"framer-1j1ydqw"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"V6U3OzX36"} style={{"--extracted-r6o4lv": "var(--variable-reference-PH9HMfixY-uqiCD9Ms9)", "--variable-reference-PH9HMfixY-uqiCD9Ms9": PH9HMfixY}} text={Bg5qY330d} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rci6D.framer-1nk7f5s, .framer-rci6D .framer-1nk7f5s { display: block; }", ".framer-rci6D.framer-14ziw1k { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 295px; }", ".framer-rci6D .framer-1ilptbr-container { aspect-ratio: 1 / 1; flex: none; height: 17px; position: relative; width: var(--framer-aspect-ratio-supported, 17px); }", ".framer-rci6D .framer-1j1ydqw { -webkit-user-select: none; flex: 1 0 0px; height: auto; pointer-events: auto; position: relative; user-select: none; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rci6D.framer-14ziw1k { gap: 0px; } .framer-rci6D.framer-14ziw1k > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-rci6D.framer-14ziw1k > :first-child { margin-left: 0px; } .framer-rci6D.framer-14ziw1k > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 17
 * @framerIntrinsicWidth 295
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"Bg5qY330d":"title","PH9HMfixY":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameruqiCD9Ms9: React.ComponentType<Props> = withCSS(Component, css, "framer-rci6D") as typeof Component;
export default FrameruqiCD9Ms9;

FrameruqiCD9Ms9.displayName = "ListItem";

FrameruqiCD9Ms9.defaultProps = {height: 17, width: 295};

addPropertyControls(FrameruqiCD9Ms9, {Bg5qY330d: {defaultValue: "Weniger X", displayTextArea: false, title: "Title", type: ControlType.String}, PH9HMfixY: {defaultValue: "rgb(255, 255, 255)", title: "Color", type: ControlType.Color}} as any)

addFonts(FrameruqiCD9Ms9, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})